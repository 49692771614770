import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "@layouts/Layout"
import Error from "@components/error/Error"

const NotFoundPage = () => (
  <Layout>
    <Error />
  </Layout>
)

export default injectIntl(NotFoundPage)
