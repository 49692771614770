import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"

import "./Error.scss"

function Error({ intl }) {
  return (
    <div className="Error">
      <span className="Error-number">404</span>
      <div className="Error-container">
        <div className="edito">
          <h1>{intl.formatMessage({ id: "error_title" })}</h1>
          <p>
            {intl.formatMessage({ id: "error_description" })}
            <br />
            <br />
            <Link to="/">
              {intl.formatMessage({ id: "error_link" })}
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Error)
